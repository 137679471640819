<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Profile Verification
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">Upload Supporting Document</span>
      </div>
      <div class="card-toolbar">
        <button type="reset" class="btn btn-success mr-2" @click="save()" ref="kt_save_changes">
          Attach Document
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          Cancel
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <!--begin::Alert-->
            <div class="alert alert-custom alert-light-danger fade show mb-10" role="alert">
              <div class="alert-icon">
                <span class="svg-icon svg-icon-3x svg-icon-danger">
                  <!--begin::Svg Icon | path:assets/media/svg/icons/Code/Info-circle.svg-->
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                    height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24" />
                      <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                      <rect fill="#000000" x="11" y="10" width="2" height="7" rx="1" />
                      <rect fill="#000000" x="11" y="7" width="2" height="2" rx="1" />
                    </g>
                  </svg>
                  <!--end::Svg Icon-->
                </span>
              </div>
              <div class="alert-text font-weight-bold">
                Upload your utility bill as a supporting document. Make sure
                that your profile name and your primary physical address should
                match with your supporting document. <br />
                Otherwise you will not be able to use the services.
              </div>
              <div class="alert-close">
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">
                    <i class="ki ki-close"></i>
                  </span>
                </button>
              </div>
            </div>
            <!--end::Alert-->
          </div>
        </div>
        <!--begin::Heading-->
        <div class="row">
          <div class="col-12">
            <h4 class="font-weight-bold mb-6">Upload Kwc Documents</h4>
            <p>You are required to uplod 2 documents for your account verification.
            <ul class="ml-6">
              <li> Utility Bill with your clear address</li>
              <li> Other</li>
              
            </ul>
            </p>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">

            <div class="form-group">
              <label class="font-weight-bold">Select Document Type</label>
              <select class="form-control" v-model="type">
                <!-- <option value="0" selected disabled> -- Type -- </option> -->
                <option value="ubill" selected> Utility Bill</option>
                <option value="nid"> Others </option>
               
              </select>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="form-group">
              <label class="font-weight-bold">Utility bill or Person's ID (JPG,PNG and PDF)</label>
              <b-form-file accept=".jpg, .png, .gif, .pdf" plain class="form-control" @change="onFileChange($event)"
                v-model="file"></b-form-file>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12"></div>

          <!--begin::Form Group-->
        </div>
      </div>
    </form>
    <!--end::Form-->

    <hr />
    <div class="card-body">
      <div class="row col-12">
        <div class="table-responsive">
          <table class="table table-hover table-stripped table-borderless">
            <template v-for="(item, i) in doc">
              <tbody>
                <tr v-bind:key="i">
                  <td> <a href="" @click="viewDoc(item)">Supporting Document</a> </td>
                  <td>
                    <span v-if="item.file_type == 'nid'"> Others</span>
                    <span v-if="item.file_type == 'ubill'"> Utility Bill</span>
                  </td>
                  <td v-if="item.is_active == 0 && item.accepted == 0" class="text-danger">
                    {{ item.rejection_reason }}
                  </td>
                  <td>
                    <span v-if="item.accepted == 0" class="badge badge-warning"> Not Accepted</span>
                    <span v-if="item.accepted == 1" class="badge badge-success" > Accepted</span>
                  </td>
                  <td>
                    <span v-if="item.accepted == 0" class="badge badge-danger" @click="deleteDoc(item.id)"> Delete</span>
                  </td>
                </tr>
              </tbody>
            </template>
          </table>
        </div>

      </div>
    </div>
  </div>
  <!--end::Card-->
</template>
  
<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "ProfileVerification",
  data() {
    return {
      file: null,
      doc: null,
      type: "0",
      file_path: "",
      baseUrl: process.env.VUE_APP_FILE_URL,
    };
  },
  mounted() {
    this.getSupportingDoc();
  },
  methods: {
    onFileChange(e) {
      this.file = e.target.files[0];
    },

    save() {
      if (this.type != "0" && this.file != null) {
        this.isLoading = true;
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("file_type", this.type);
        formData.append("user_id", this.currentUser.id);
        ApiService.upload("saveSupportingDocument", formData)
          .then((response) => {
            if (response.data.success) {
              this.file_path = response.data.file
              this.getSupportingDoc();
              this.makeToastVariant(
                "success",
                "Document uploaded successfully",
                "Success"
              );
            } else {
              this.makeToastVariant(
                "danger",
                response.data.message,
                "Error"
              );
            }
          })
          .catch(({ response }) => {
            this.isLoading = false;
            for (let field of Object.keys(response.data.error)) {
              this.makeToastVariant(
                "danger",
                response.data.error[field][0],
                "Error"
              );
            }
          });
      } else {
        if (this.file == null) {
          this.makeToastVariant(
            "danger",
            "Select the document to upload",
            "Error"
          );
        } else if (this.type == "0") {
          this.makeToastVariant(
            "danger",
            "Select the document type",
            "Error"
          );
        } else {
          this.makeToastVariant(
            "danger",
            "Select the  Document type & document to upload",
            "Error"
          );
        }

      }

    },
    cancel() {
      this.file = null;
    },
    getSupportingDoc() {
      ApiService.get("getSupportingDocument", this.currentUser.id)
        .then((response) => {
          if (response.data.success) {
            this.doc = response.data.list;
          }
        })
        .catch(({ response }) => { });
    },
    downloadDocument() {
      if (this.file_path == "") {
        window.open(this.baseUrl + this.doc.file_path, "_blank");
      } else {
        window.open(this.baseUrl + this.doc.file_path, "_blank");
      }

    },
    viewDoc(item) {
      window.open(this.baseUrl + item.file_path, "_blank");
    },
    deleteDoc(id){
      ApiService.delete("deleteSupportingDocument", id)
          .then((response) => {
            if (response.data.success) {
              
              this.getSupportingDoc();
              this.makeToastVariant(
                "success",
                "Document Deleted successfully",
                "Success"
              );
            } else {
              this.makeToastVariant(
                "danger",
                response.data.message,
                "Error"
              );
            }
          })
          .catch(({ response }) => {
            this.isLoading = false;
            for (let field of Object.keys(response.data.error)) {
              this.makeToastVariant(
                "danger",
                response.data.error[field][0],
                "Error"
              );
            }
          });
    },
    makeToastVariant(variant = null, text) {
      this.$bvToast.toast(text, {
        title: `Error`,
        variant: variant,
        solid: true,
      });
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>
  