<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Personal Information
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">Update your personal informaiton</span>
      </div>
      <div class="card-toolbar">
        <button type="reset" class="btn btn-success mr-2" @click="save()" ref="kt_save_changes">
          Save Changes
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          Cancel
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mb-6">Customer Info</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Avatar</label>
          <div class="col-lg-9 col-xl-6">
            <div class="image-input image-input-outline" id="kt_profile_avatar">
              <div class="image-input-wrapper" :style="{ backgroundImage: `url(${photo})` }"></div>
              <label class="
                              btn
                              btn-xs
                              btn-icon
                              btn-circle
                              btn-white
                              btn-hover-text-primary
                              btn-shadow
                            " data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">
                <i class="fa fa-pen icon-sm text-muted"></i>
                <input type="file" name="profile_avatar" accept=".png, .jpg, .jpeg" @change="onFileChange($event)" />
                <input type="hidden" name="profile_avatar_remove" />
              </label>
              <span class="
                              btn
                              btn-xs
                              btn-icon
                              btn-circle
                              btn-white
                              btn-hover-text-primary
                              btn-shadow
                            " data-action="cancel" data-toggle="tooltip" title="Cancel avatar">
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
              <span class="
                              btn
                              btn-xs
                              btn-icon
                              btn-circle
                              btn-white
                              btn-hover-text-primary
                              btn-shadow
                            " data-action="remove" data-toggle="tooltip" title="Remove avatar"
                @click="current_photo = null">
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
            </div>
            <span class="form-text text-muted">Allowed file types: png, jpg, jpeg.</span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Name</label>
          <div class="col-lg-9 col-xl-6">
            <input ref="name" class="form-control form-control-lg form-control-solid" type="text"
              v-model="user.first_name" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Last Name</label>
          <div class="col-lg-9 col-xl-6">
            <input ref="surname" class="form-control form-control-lg form-control-solid" type="text"
              v-model="user.last_name" />
          </div>
        </div>

        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mt-10 mb-6">Contact Info</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Contact Phone</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-phone"></i>
                </span>
              </div>
              <input ref="phone" type="text" class="form-control form-control-lg form-control-solid" placeholder="Phone"
                v-model="user.phone" />
            </div>
            <span class="form-text text-muted">We'll never share your email with anyone else.</span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Email Address</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-at"></i>
                </span>
              </div>
              <input ref="email" type="email" class="form-control form-control-lg form-control-solid" placeholder="Email"
                v-model="user.email" />
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Address</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-at"></i>
                </span>
              </div>
              <input ref="address" type="text" class="form-control form-control-lg form-control-solid"
                placeholder="Address" v-model="user.address" />
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">City</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-at"></i>
                </span>
              </div>
              <input ref="email" type="text" class="form-control form-control-lg form-control-solid" placeholder="Email"
                v-model="user.city" />
            </div>
          </div>
        </div>

      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { RESET_AUTH } from "@/core/services/store/auth.module";
import ApiService from "@/core/services/api.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapGetters } from "vuex";
export default {
  name: "PersonalInformation",
  data() {
    return {
      default_photo: "media/users/blank.png",
      current_photo: null,
      user: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        address:"",
        "city":""
      },
      file_url: process.env.VUE_APP_FILE_URL,
    };
  },
  components: {
    Loading,
  },
  mounted() {
    this.current_photo = this.file_url + this.currentUser.profile_path;
    this.lodeValues();
  },
  methods: {
    lodeValues() {
      ApiService.get("getuser", this.currentUser.id)
        .then(({ data }) => {

          this.user.first_name = data.first_name;
          this.user.last_name = data.last_name;
          this.user.email = data.email;
          this.user.phone = data.phone;
          this.user.address = data.address;
          this.user.city = data.address;
          console.log(this.user);
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },
    save() {
      this.isLoading = true;
      ApiService.update("updateuser", this.currentUser.id, this.user)
        .then((resp) => {
          this.$nextTick(() => {
          });

          this.currentUser.first_name = this.user.first_name;
          this.currentUser.last_name = this.user.last_name;
          this.currentUser.email = this.user.email;
          this.currentUser.phone = this.user.phone;
          this.$store
            .dispatch(RESET_AUTH, this.currentUser.id, this.user)
        })
        .catch(({ response }) => {
          this.isLoading = false;
          for (let field of Object.keys(response.data.error)) {
          }
        });
      // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send update request
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },
    cancel() {
      this.$refs.first_.value = this.currentUser.first_name;
      this.$refs.surname.value = this.currentUser.last_name;
      this.$refs.phone.value = this.currentUser.phone;
      this.$refs.email.value = this.currentUser.email;
      this.current_photo = this.currentUser.profile_path;
    },
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };

        this.isLoading = true;
        let formData = new FormData();
        formData.append("file", file);
        formData.append("user_id", this.currentUser.id);
        ApiService.upload("saveProfileImage", formData)
          .then((response) => {
            if (response.data.success) {
              this.isLoading = false;

            }
          })
          .catch(({ response }) => {
            this.isLoading = false;
            for (let field of Object.keys(response.data.error)) {
              // this.makeToastVariant(
              //   "danger",
              //   response.data.error[field][0],
              //   "Error"
              // );
            }
          });
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },

  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    ...mapGetters(["currentUser"]),
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
  },
};
</script>
