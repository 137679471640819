<template>
  
    <!--begin::Advance Table Widget 10-->
    <div class="card card-custom gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">Account Activity</span>
        </h3>
        <div class="card-toolbar"></div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body py-0">
        <!--begin::Table-->
        <div class="table-responsive">
          <table class="table table-head-custom table-vertical-center" id="kt_advance_table_widget_4">
            <thead>
              <tr class="text-left">
                <th class="pl-0" style="min-width: 120px">Login Time</th>
                <th style="min-width: 110px">Activity</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, i) in list">
                <tr v-bind:key="i">
                  <td class="pl-0">
                    {{ new Date(item.created_at) }}
                  </td>
                  <td>
                    <span>User Login successfully</span>

                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
      </div>
      <!--end::Body-->
    </div>
    <!--end::Advance Table Widget 10-->

</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
export default {
  name: "widget-2",
  data() {
    return {
      checked: false,
      list: []
    };
  },
  components: {},
  methods: {
    setCheck(check) {
      if (check) {
        this.checked = check;
      } else {
        this.checked = false;
      }
    },
    getActivityList() {
      ApiService.get("getLoginActivity", this.currentUser.id)
        .then(({ data }) => {
          this.list = data;
        })
        .catch(({ response }) => {
        });
    },
  },
  mounted() {
    this.getActivityList();
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>
