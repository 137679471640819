<template>
  <div class="row">
    <!--begin::Aside-->
    <div
      class="col-md-3 col-lg-3 col-sm-12  w-xl-350px"
      id="kt_profile_aside"
    >
      <!--begin::Card-->
      <div class="card card-custom">
        <!--begin::Body-->
        <div class="card-body pt-15">
          <!--begin::User-->
          <div class="text-center mb-10">
            <div class="symbol symbol-60 symbol-circle symbol-xl-90">
              <div
                class="symbol-label"
                :style="{
                  backgroundImage: `url(${profile_photo})`
                }"
              ></div>
              <i class="symbol-badge symbol-badge-bottom bg-success"></i>
            </div>

            <h4 class="font-weight-bold my-2">
              {{currentUser.first_name }} {{currentUser.last_name}}
            </h4>
            <div class="text-muted mb-2">
              
            </div>
            <span v-if="currentUser.is_active == 1 && currentUser.kwc_required == 0"
              class="label label-light-success label-inline font-weight-bold label-lg"
              >Verified</span>

              <span v-if="currentUser.is_active == 1 && currentUser.kwc_required == 1 && currentUser.role == 6"
              class="label label-light-warning label-inline font-weight-bold label-lg"
              >Verification Required</span>
          </div>
          <!--end::User-->

          <!--begin::Nav-->
          <div
            class="navi navi-bold navi-hover navi-active navi-link-rounded"
            role="tablist"
          >
            <div class="nav-item mb-2">
              <a
                class="btn btn-hover-light-primary font-weight-bold py-3 px-6 mb-2 text-center btn-block active"
                @click="setActiveTab"
                style="cursor:pointer"
                data-tab="0"
                data-toggle="tab"
                role="tab"
              >
                Profile Overview
              </a>
              <a
                class="btn btn-hover-light-primary font-weight-bold py-3 px-6 mb-2 text-center btn-block"
                @click="setActiveTab"
                style="cursor:pointer"
                data-tab="1"
                data-toggle="tab"
                role="tab"
              >
                Personal info
              </a>
              <a
                class="btn btn-hover-light-primary font-weight-bold py-3 px-6 mb-2 text-center btn-block"
                @click="setActiveTab"
                style="cursor:pointer"
                data-tab="2"
                data-toggle="tab"
                role="tab"
              >
                Account Info
              </a>
              <a
                class="btn btn-hover-light-primary font-weight-bold py-3 px-6 mb-2 text-center btn-block"
                @click="setActiveTab"
                style="cursor:pointer"
                data-tab="3"
                data-toggle="tab"
                role="tab"
              >
                Change Password
              </a>
              <a v-if="currentUser.role == 6"
                class="btn btn-hover-light-primary font-weight-bold py-3 px-6 mb-2 text-center btn-block"
                @click="setActiveTab"
                style="cursor:pointer"
                data-tab="4"
                data-toggle="tab"
                role="tab"
              >
                Profile Verification
              </a>
            </div>
          </div>
          <!--end::Nav-->
        </div>
        <!--end::Body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Aside-->

    <!--begin::Content-->
    <div class="col-md-9 col-sm-12 col-lg-9">
      <b-tabs class="hide-tabs" v-model="tabIndex">
        <b-tab active>
          <Widget2></Widget2>
        </b-tab>

        <b-tab>
          <KTPersonalInformation></KTPersonalInformation>
        </b-tab>

        <b-tab>
          <KTAccountInformation></KTAccountInformation>
        </b-tab>

        <b-tab>
          <KTChangePassword></KTChangePassword>
        </b-tab>

        <b-tab>
          <KTProfileVerification></KTProfileVerification>
        </b-tab>
       
      </b-tabs>
    </div>
    <!--end::Content-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Widget1 from "@/view/pages/profile/profile-comp-2/Widget1";
import Widget2 from "@/view/pages/profile/profile-comp-2/Widget2";
import Widget14 from "@/view/pages/profile/profile-comp-2/Widget14";
import KTPersonalInformation from "@/view/pages/profile/profile-comp/PersonalInformation";
import KTAccountInformation from "@/view/pages/profile/profile-comp/AccountInformation";
import KTChangePassword from "@/view/pages/profile/profile-comp/ChangePassword";
import KTProfileVerification from "@/view/pages/profile/profile-comp/ProfileVerification";

export default {
  name: "Profile-2",
  data() {
    return {
      tabIndex: 0,
      file_url: process.env.VUE_APP_FILE_URL,
      profile_photo: ""
    };
  },
  components: {
    Widget1,
    Widget2,
    Widget14,
    KTPersonalInformation,
    KTAccountInformation,
    KTChangePassword,
    KTProfileVerification
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "User Profile" }]);
    this.profile_photo = this.file_url + this.currentUser.profile_path;
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll('[data-toggle="tab"]');
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    }
  },
  computed: {
    ...mapGetters(["currentUserPhoto"]),
    ...mapGetters(["currentUser"]),
  }
};
</script>
